@tailwind base;
@tailwind components;
@tailwind utilities;


model-viewer{
    width: 100%;
    height: 100%;
}

.mobile-view {
    height: calc(100vh - 50px);
}

model-viewer[ar-status="failed"] > #error {
    display: block;
}